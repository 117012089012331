import React from 'react';
import styles from './Modal.module.css';

const Modal = ({ isOpen, onClose, title, intro, details }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>
          <span className="material-symbols-outlined">close</span>
        </button>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.intro}>{intro}</p>
        <div className={styles.details}>
          {details?.map((detail, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: detail }} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Modal;
