import React from 'react';
import profileImg from '../medias/RealProfile.png';
import githubIcon from '../medias/github.svg';
import linkedinIcon from '../medias/linkedin.svg';
import styles from './Header.module.css';

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles['header-content']}>
        <div className={styles['header-text']}>
          <h1>Yaron Jing</h1>
          <h2>Full Stack Developer & UI Designer</h2>
          <p className={styles.education}>Computer Engineering BASc 1A</p>
          <p className={styles.university}>University of Waterloo</p>
          <div className={styles['social-links']}>
            <a href="https://github.com/lacser?tab=repositories" target="_blank" rel="noopener noreferrer">
              <img src={githubIcon} alt="GitHub" />
            </a>
            <a href="https://www.linkedin.com/in/yaron-ching/" target="_blank" rel="noopener noreferrer">
              <img src={linkedinIcon} alt="LinkedIn" />
            </a>
            <a href="mailto:yaron.jing@uwaterloo.ca" className={styles['email-link']}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="#4c662b">
                <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
              </svg>
            </a>
          </div>
        </div>
        <div className={styles['profile-image']}>
          <img src={profileImg} alt="Yaron Jing" />
        </div>
      </div>
    </header>
  );
};

export default Header;
