import React, { useState, useEffect, useRef } from 'react';
import styles from './Projects.module.css';
import projectsData from '../data/projects.json';
import Modal from './Modal';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [currentImage, setCurrentImage] = useState({});
  const [hoveredCard, setHoveredCard] = useState(null);
  const [videoProgress, setVideoProgress] = useState({});
  const [selectedProject, setSelectedProject] = useState(null);
  const [expandedCards, setExpandedCards] = useState({});
  const timersRef = useRef({});
  const mediaElementRefs = useRef({});

  useEffect(() => {
    const importAll = (r) => {
      let files = {};
      r.keys().forEach((item) => {
        files[item] = r(item);
      });
      return files;
    };

    // Import all media files from the medias directory
    const mediaFiles = importAll(require.context('../medias', true, /\.(png|jpe?g|mp4)$/));
    
    // Import all description files
    const descriptionFiles = importAll(require.context('../medias', true, /description\.json$/));
    
    // Process the projects data and attach media files and descriptions
    const processedProjects = projectsData.projects.map(project => {
      const projectMedia = Object.keys(mediaFiles)
        .filter(path => path.includes(project.mediaFolder))
        .map(path => ({
          src: mediaFiles[path],
          type: path.endsWith('.mp4') ? 'video' : 'image'
        }));
      
      // Find and parse the description file for this project
      const descriptionFile = Object.keys(descriptionFiles)
        .find(path => path.includes(project.mediaFolder));
      const description = descriptionFile ? descriptionFiles[descriptionFile] : null;
      
      return {
        ...project,
        media: projectMedia,
        description: description
      };
    });

    setProjects(processedProjects);
  }, []);

  const startImageTimer = (projectId, currentIdx) => {
    const project = projects.find((p, index) => index === projectId);
    if (!project?.media?.length) return;

    const media = project.media[currentIdx];
    const duration = media.type === 'video' ? 0 : 4000; // Don't auto-advance videos

    if (duration > 0) {
      // Clear existing timer if any
      if (timersRef.current[projectId]) {
        clearTimeout(timersRef.current[projectId]);
      }

      // Set new timer
      timersRef.current[projectId] = setTimeout(() => {
        handleImageChange(projectId, (currentIdx + 1) % project.media.length);
      }, duration);
    }
  };

  const handleImageChange = (projectId, newIndex) => {
    // Clear existing timer
    if (timersRef.current[projectId]) {
      clearTimeout(timersRef.current[projectId]);
    }
    
    setCurrentImage(prev => ({ ...prev, [projectId]: newIndex }));

    // Start new timer for the new image
    startImageTimer(projectId, newIndex);
  };

  const handleVideoTimeUpdate = (projectIndex, event) => {
    const video = event.target;
    const progress = (video.currentTime / video.duration) * 100;
    setVideoProgress(prev => ({
      ...prev,
      [projectIndex]: progress
    }));
  };

  const toggleExpand = (projectIndex, event) => {
    event.stopPropagation();
    setExpandedCards(prev => ({
      ...prev,
      [projectIndex]: !prev[projectIndex]
    }));
  };

  useEffect(() => {
    // Start timers for all projects
    projects.forEach((_, index) => {
      const currentIdx = currentImage[index] || 0;
      startImageTimer(index, currentIdx);
    });

    // Cleanup function
    return () => {
      Object.values(timersRef.current).forEach(timer => {
        clearTimeout(timer);
      });
      timersRef.current = {};
    };
  }, [projects, currentImage]);

  const renderMedia = (project, projectIndex) => {
    if (!project.media?.length) return null;

    const currentIdx = currentImage[projectIndex] || 0;
    const media = project.media[currentIdx];

    return (
      <div 
        className={styles.mediaContainer}
        onMouseEnter={() => setHoveredCard(projectIndex)}
        onMouseLeave={() => setHoveredCard(null)}
      >
        <div className={styles.projectMediaWrapper}>
          {media.type === 'video' ? (
            <video
              key={media.src}
              ref={el => mediaElementRefs.current[projectIndex] = el}
              src={media.src}
              autoPlay
              muted
              playsInline
              className={styles.projectMedia}
              onEnded={() => handleImageChange(projectIndex, (currentIdx + 1) % project.media.length)}
              onTimeUpdate={(e) => handleVideoTimeUpdate(projectIndex, e)}
            />
          ) : (
            <img
              key={media.src}
              ref={el => mediaElementRefs.current[projectIndex] = el}
              src={media.src}
              alt={`${project.title} showcase`}
              className={styles.projectMedia}
            />
          )}
          
          {project.media.length > 1 && (
            <div className={styles.mediaControls}>
              <button
                className={`${styles.navButton} ${styles.prev}`}
                onClick={(e) => {
                  e.stopPropagation();
                  const newIdx = (currentIdx - 1 + project.media.length) % project.media.length;
                  handleImageChange(projectIndex, newIdx);
                }}
              >
                <span className="material-symbols-outlined mini">chevron_left</span>
              </button>
              
              <div className={styles.dots}>
                {project.media.map((_, index) => (
                  <span
                    key={index}
                    className={`${styles.dot} ${index === currentIdx ? styles.active : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageChange(projectIndex, index);
                    }}
                  >
                    {index === currentIdx && (
                      <div 
                        className={`${styles.progress} ${media.type === 'image' ? styles.imageProgress : ''}`}
                        style={{
                          transform: media.type === 'video' ? `scaleX(${videoProgress[projectIndex] / 100 || 0})` : undefined
                        }}
                      />
                      )}
                  </span>
                ))}
              </div>

              <button
                className={`${styles.navButton} ${styles.next}`}
                onClick={(e) => {
                  e.stopPropagation();
                  const newIdx = (currentIdx + 1) % project.media.length;
                  handleImageChange(projectIndex, newIdx);
                }}
              >
                <span className="material-symbols-outlined mini">chevron_right</span>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.sectionTitle}>My Projects</h2>
      {projects.map((project, index) => (
        <div 
          key={index} 
          className={`${styles.card} ${expandedCards[index] ? styles.expanded : ''}`}
          style={{ zIndex: hoveredCard === index ? 10 : 1 }}
        >
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <h3>{project.title}</h3>
            </div>
            <div className={styles.tags}>
              {project.technologies.map((tech, techIndex) => (
                <span key={techIndex} className={styles.tag}>
                  {tech}
                </span>
              ))}
            </div>
            <p className={styles.description}>
              {project.description?.intro}
            </p>

            {/* Always render the expandedContent */}
            {project.description?.description && (
              <div className={styles.expandedContent}>
                <ul>
                  {project.description.description.map((detail, detailIndex) => (
                    <li key={detailIndex} dangerouslySetInnerHTML={{ __html: detail }} />
                  ))}
                </ul>
              </div>
            )}

            <button 
              className={`${styles.expandButton} ${expandedCards[index] ? styles.expanded : ''}`}
              onClick={(e) => toggleExpand(index, e)}
            >
              <span className="material-symbols-outlined">
                {expandedCards[index] ? 'expand_less' : 'expand_more'}
              </span>
            </button>
          </div>
          {renderMedia(project, index)}
        </div>
      ))}
      
      <Modal
        isOpen={selectedProject !== null}
        onClose={() => setSelectedProject(null)}
        title={selectedProject?.title}
        intro={selectedProject?.description?.intro}
        details={selectedProject?.description?.description}
      />
    </div>
  );
};

export default Projects;
