import React, { useEffect, useRef, useState } from 'react';
import skillsData from '../skills.json';
import styles from './Skills.module.css';

const Skills = () => {
  const trackRefs = useRef([]);
  const [processedSkills, setProcessedSkills] = useState(skillsData.skillRows);

  useEffect(() => {
    const processRows = skillsData.skillRows.map(row => {
      if (row.length < 12) {
        const repeatedSkills = [...row];
        while (repeatedSkills.length < 12) {
          repeatedSkills.push(...row);
        }
        return repeatedSkills;
      }
      return row;
    });
    setProcessedSkills(processRows);
  }, []);

  useEffect(() => {
    trackRefs.current.forEach((track) => {
      if (track) {
        const trackWidth = track.scrollWidth;
        track.style.setProperty('--track-width', `${trackWidth}px`);
        const animationDuration = trackWidth / 50;
        track.style.setProperty('--animation-duration', `${animationDuration}s`);
      }
    });
  }, [processedSkills]);

  return (
    <section className={styles.root}>
      <h2 className={styles.title}>My Skills</h2>
      <div className={styles.container}>
        {processedSkills.map((row, index) => (
          <div key={index} className={styles.carousel}>
            <div className={`${styles.overlay} ${styles.overlayLeft}`}></div>
            <div className={`${styles.overlay} ${styles.overlayRight}`}></div>
            <div
              className={styles.track}
              style={{ animationDelay: `${index * -3.75}s` }}
              ref={el => {
                trackRefs.current[index] = el;
              }}
            >
              {/* First copy of tags */}
              {row.map((skill, skillIndex) => (
                <span key={`${skill}-1-${skillIndex}`} className={styles.tag}>
                  {skill}
                </span>
              ))}
              {/* Second copy of tags */}
              {row.map((skill, skillIndex) => (
                <span key={`${skill}-2-${skillIndex}`} className={styles.tag}>
                  {skill}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
